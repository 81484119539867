/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
// @import "~@ionic/angular/css/normalize.css";
// @import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
// @import '~@ionic/angular/css/display.css';

// /* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@font-face {   
    font-family: 'Montserrat';    //This is what we are going to call
    font-weight: normal;
    src: url('../src/assets/fonts/Montserrat/Montserrat-Regular.ttf');
  }

@font-face {   
    font-family: 'Montserrat';    //This is what we are going to call
    font-weight: bold;
    src: url('../src/assets/fonts/Montserrat/Montserrat-Bold.ttf');
  }

:root {
  --ion-font-family: 'Montserrat', 'Helvetica Neue', 'Roboto', sans-serif;
}

// HEADER SECTION
.orange-header {
  // padding-top: 27px;
  // padding-bottom: 27px;
  border-bottom-left-radius: 45px;
  background-color: #FF7900;
  box-shadow: 0px -3px 7px -1px rgba(0, 0, 0, 0);
  ;
  
}

.bold-title {
  text-align: center;
  color: white;
  font-weight: 600;
}

.alert-wrapper.sc-ion-alert-md {
  border-radius: 1rem;
  padding: 0.6rem;
}

.alert-title.sc-ion-alert-md {
  font-weight: 600;
}

.toast-wrapper {
  border-radius: 0.8rem;
}
  



